<template>
    <div id="profile-img" class="pa-3 text-center">
      <v-dialog
        v-model="dialog"
        @click="changeStateDialog(true)"
        :persistent="!isMobile"
        :fullscreen="isMobile"
        :hide-overlay="isMobile"
        max-width="600px"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }" v-if="!isMobile">
            <v-avatar
              v-bind="attrs" v-on="on"
              class="profile rounded-circle d-block ma-auto"
              color="grey"
              size="164"
              tile
            >
              <v-img
                v-if="avatarImg"
                alt="Avatar"
                :src="avatarImg"
              >
                <v-expand-transition>
                  <div
                    v-if="hover && !isMobile"
                    class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text"
                  >
                   <v-icon
                        color="#fff"
                        v-text="'mdi-pencil'"
                    ></v-icon>
                  </div>
                </v-expand-transition>
              </v-img>
              <v-icon
                v-else
                color="#fff"
                size="100"
                v-text="avatarIcon"
              >
                <v-expand-transition>
                  <div
                    v-if="hover && !isMobile"
                    class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text"
                  >
                    <v-icon
                        color="#fff"
                        v-text="'mdi-pencil'"
                    ></v-icon>
                  </div>
                </v-expand-transition>
              </v-icon>
            </v-avatar>
          </v-hover>
          <v-avatar
            v-else
            v-bind="attrs" v-on="on"
            class="profile rounded-circle d-block ma-auto"
            color="grey"
            size="164"
            tile
          >
            <v-img
              v-if="avatarImg"
              alt="Avatar"
              :src="avatarImg"
            >
              <v-expand-transition>
                <div
                  class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text"
                >
                  <v-icon
                      color="#fff"
                      v-text="'mdi-pencil'"
                  ></v-icon>
                </div>
              </v-expand-transition>
            </v-img>
            <v-icon
              v-else
              color="#fff"
              size="100"
              v-text="avatarIcon"
            >
              <v-expand-transition>
                <div
                  class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text"
                >
                  <v-icon
                      color="#fff"
                      v-text="'mdi-pencil'"
                  ></v-icon>
                </div>
              </v-expand-transition>
            </v-icon>
          </v-avatar>
        </template>
        <modal-profile></modal-profile>
    </v-dialog>

    
    <h5 class="mt-3" style="color:#fff">{{employeeId}}</h5>
    <h3 style="color:#fff">{{fullName}}</h3>
    <v-chip
      class="ma-2 px-5"
      color="white"
      outlined
      label
    >
      <strong>{{role}}</strong>
    </v-chip>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ModalProfile from "./ModalProfile.vue";
export default {
    name: "SideMenuProfile",
    components: { ModalProfile },
    data: () => ({
        avatarImg: require(`@/assets/images/avatar.png`),
        avatarIcon: "mdi-account"
    }),
    computed: {
      ...mapState({
        user: state => state.account.user,
        isMobile: state => state.global.isMobile,
        dialogStatus: state => state.account.dialogStatus
      }),
      fullName: function(){
        return this.user ? `${this.user.firstname} ${this.user.lastname}` : null;
      },
      role: function(){
        return this.user ? this.user.role.description : null;
      },
      employeeId: function(){
        return this.user ? this.user.employeeId : null;
      },
      dialog: {
        get(){
          return this.dialogStatus
        },
        set(status){
          return this.changeStateDialog(status)
        }
      }
    },
    methods: {
      ...mapMutations({
        changeStateDialog: 'account/SET_DIALOG_STATUS'
      })
    }
}
</script>