<template>
    <v-card>
        <v-toolbar
          v-if="isMobile"
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeDialog(false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>User Profile</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="closeDialog(false)"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title v-if="!isMobile">
            <span class="headline">User Profile</span>
        </v-card-title>
        <v-card-text>
            <v-container>
            <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="py-1"
                >
                  <v-text-field
                      label="First name*"
                      v-model="user.firstname"
                      outlined
                      required
                      dense
                      
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="py-1"
                >
                  <v-text-field
                      label="Last name*"
                      v-model="user.lastname"
                      outlined
                      required
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-text-field
                      label="Telephone"
                      v-model="user.telephone"
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-text-field
                      label="Service Branch"
                      v-model="user.serviceBranch"
                      outlined
                      disabled
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-text-field
                      label="Role"
                      v-model="user.role.name"
                      outlined
                      disabled
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-text-field
                      label="Team Lead"
                      v-model="teamLeader"
                      outlined
                      disabled
                      dense
                  ></v-text-field>
                </v-col>
            </v-row>
            </v-container>
            <small>*indicates required field</small>
            <v-spacer></v-spacer>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
              class="px-5"
              color="light"
              @click="closeDialog(false)"
            >
                Close
            </v-btn>
            <v-btn
              class="px-5"
              color="primary"
              @click="closeDialog(false)"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
    name: "ModalProfile",
    computed: {
      ...mapState({
        isMobile: state => state.global.isMobile,
        dialog: state => state.account.dialogStatus,
        user: state => state.account.user
      }),
      teamLeader() {
        return this.user.teamLeader ? `${this.user.teamLeader.employeeId} - ${this.user.teamLeader.firstname}` : `-` ;
      }
    },
     methods: {
      ...mapMutations({
        closeDialog: 'account/SET_DIALOG_STATUS'
      })
    }
}
</script>