<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    :value="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    color="primary"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>
    
    <!-- <div id="company-logo" class="pa-3">
      <v-img class="rounded-circle" :src="logoPath" width="60" />
    </div> -->

    <side-menu-profile></side-menu-profile>

    <v-list expand nav>
      <template v-for="(item, i) in computedItemsFrom">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item"></base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import SideMenuProfile from "@/components/profile/SideMenuProfile.vue";

export default {
  name: "DashboardCoreDrawer",
  components: {
    SideMenuProfile
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    logoPath: require(`@/assets/images/logo.png`),
    itemsForm: [
      {
        icon: "mdi-view-dashboard",
        title: "sideMenus.jobs",
        to: "/job"
      },
      {
        icon: "mdi-file-chart",
        title: "sideMenus.reports",
        to: "/report"
      }
    ],
    itemsSetting: [],
    items: []
  }),
  computed: {
    ...mapState({
      barColor: state => state.global.barColor,
      barImage: state => state.global.barImage,
      drawer: state => state.global.drawer,
    }),
    computedItemsFrom() {
      return this.itemsForm.map(this.mapItem);
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    computeditemsSetting() {
      return this.itemsSetting.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("sideMenus.avatar")
      };
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: "global/SET_DRAWER"
    }),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
    }
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.v-card--reveal 
  align-items: center
  bottom: 0
  justify-content: center
  opacity: .5
  position: absolute
  width: 100%


#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
      margin-right: 24px
      margin-left: 12px !important

      +rtl()
      margin-left: 24px
      margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
      padding-left: 8px

      +rtl()
      padding-right: 8px

    .v-list-group__header
      +ltr()
      padding-right: 0

      +rtl()
      padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
        margin-right: 8px

        +rtl()
        margin-left: 8px
</style>
